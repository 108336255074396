import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Comprimento from './components/comprimento';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <h1>Conversor</h1>
    <div className='container-main'>
      <Comprimento />

    </div>
  </React.StrictMode>
);
