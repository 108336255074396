import React, { useState, useEffect } from 'react';
import './comprimento.css'

function Comprimento() {

    const [converter, setConverter] = useState('');
    const [resultado, setResultado] = useState('resultado');
    const [unidadeConversao, setUnidadeConversao] = useState('4');
    const [unidadeConvertida, setUnidadeConvertida] = useState('9');

    const fracaoPolegadas = {
        0.0625: '1/16',
        0.125: '1/8',
        0.1875: '3/16',
        0.25: '1/4',
        0.3125: '5/16',
        0.375: '3/8',
        0.4375: '7/16',
        0.5: '1/2',
        0.5625: '9/16',
        0.625: '5/8',
        0.6875: '11/16',
        0.75: '3/4',
        0.8125: '13/16',
        0.875: '7/8',
        0.9375: '15/16',
        1: '1',
    };

    const handleChange = (e) => {
        setUnidadeConversao(e.target.value);
    };

    const handleChanged = (e) => {
        setUnidadeConvertida(e.target.value);
    };

    function converteInput(valor) {
        switch (unidadeConversao) {
            case '0': return valor * 1000000;
            case '1': return valor * 1000;
            case '2': return valor * 100;
            case '3': return valor * 10;
            case '4': return valor;
            case '5': return valor * 0.000001;
            case '6': return valor * 1609344
            case '7': return valor * 914.4
            case '8': return valor * 304.8
            case '9': return valor * 25.4
            case '10': return valor * 1852000
            case '11': return valor * 0.001
            default: return;
        }
    }

    useEffect(() => {

        switch (unidadeConvertida) {
            case '0': return converteKm();
            case '1': return converteMetro();
            case '2': return converteDm();
            case '3': return converteCm();
            case '4': return converteMm();
            case '5': return converteNm();
            case '6': return converteMilha();
            case '7': return converteJarda();
            case '8': return convertePe();
            case '9': return convertePolegada();
            case '10': return converteMilhaNautica();
            case '11': return converteMicrometro();
            default: return;
        }

        function convertePolegada() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const polegadas = mm / 25.4;
                const polegadasTratado = polegadas.toFixed(3);
                const parteInteira = Math.floor(polegadas);
                const parteDecimal = polegadas - parteInteira;

                let menorDiferenca = Infinity;

                for (const fracao in fracaoPolegadas) {
                    const diferenca = Math.abs(parteDecimal - parseFloat(fracao));
                    if (diferenca < menorDiferenca) {
                        menorDiferenca = diferenca;
                    }
                }

                const fracaoParteDecimal = Math.round(parteDecimal * 16);
                if (fracaoParteDecimal === 16) {
                    setResultado(`${polegadasTratado}" ≈ ${parteInteira + 1}"`);
                } else if (fracaoParteDecimal > 0) {
                    if (parteInteira === 0) {
                        setResultado(`${polegadasTratado}" ≈ ${fracaoPolegadas[fracaoParteDecimal / 16]}"`);
                    } else {
                        setResultado(`${polegadasTratado}" ≈ ${parteInteira} ${fracaoPolegadas[fracaoParteDecimal / 16]}"`);
                    }
                } else {
                    setResultado(`${polegadasTratado}" ≈ ${parteInteira}"`);
                }
            } else {
                setResultado('');
            }
        }

        function converteKm() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const kilometro = mm / 1000000;
                setResultado(kilometro)
            } else {
                setResultado('');
            }
        }

        function converteMetro() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const metro = mm / 1000;
                setResultado(metro)
            } else {
                setResultado('');
            }
        }

        function converteDm() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const decimetro = mm / 100;
                setResultado(decimetro)
            } else {
                setResultado('');
            }
        }

        function converteCm() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const centimetro = mm / 10;
                setResultado(centimetro)
            } else {
                setResultado('');
            }
        }

        function converteMm() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const milimetro = mm;
                setResultado(milimetro)
            } else {
                setResultado('');
            }
        }

        function converteNm() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const nanometro = mm / 0.000001;
                setResultado(nanometro)
            } else {
                setResultado('');
            }
        }

        function converteMilha() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const milha = mm / 1609344;
                setResultado(milha)
            } else {
                setResultado('');
            }
        }

        function converteJarda() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const jarda = mm / 914.4;
                setResultado(jarda)
            } else {
                setResultado('');
            }
        }

        function convertePe() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const pe = mm / 304.8;
                setResultado(pe)
            } else {
                setResultado('');
            }
        }

        function converteMilhaNautica() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const milhaNautica = mm / 1852000;
                setResultado(milhaNautica)
            } else {
                setResultado('');
            }
        }
        
        function converteMicrometro() {
            const mmValue = converter.replace(',', '.');
            let mm = parseFloat(mmValue);
            mm = converteInput(mm);

            if (!isNaN(mm)) {
                const micrometro = mm / 0.001;
                setResultado(micrometro)
            } else {
                setResultado('');
            }
        }

    }, [converter]);

    return (
        <div className='container'>
            <select name='tipo' id='tipo-unidade'>
                <option value='0' selected>Comprimento</option>
            </select>

            <div className='inputs'>
                <div className='converter'>
                    <input type="number" value={converter} onChange={(e) => setConverter(e.target.value)} placeholder='entre com o valor' />
                    <select name="tipo" id="unidade-a-converter" value={unidadeConversao} onChange={handleChange}>
                        <option value="0" >Quilômetro (km)</option>
                        <option value="1" >Metro (m)</option>
                        <option value="2" >Decímetro (dm)</option>
                        <option value="3" >Centímetro (cm)</option>
                        <option value="4" selected>Milímetro (mm)</option>
                        <option value="11" >Micrômetro (μm)</option>
                        <option value="5" >Nanômetro (nm)</option>
                        <option value="6" >Milha (mi)</option>
                        <option value="10" >Milha Náutica (nmi)</option>
                        <option value="7" >Jarda (yd)</option>
                        <option value="8" >Pé (ft)</option>
                        <option value="9" >Polegada (in)</option>
                    </select>
                </div>

                <div style={{ fontSize: '32px', fontWeight: 'bold' }}>=</div>

                <div className='convertido'>
                    <input type='number' placeholder={resultado ? resultado : 'resultado'} disabled />
                    <select name="tipo" id="unidade-convertida" value={unidadeConvertida} onChange={handleChanged}>
                        <option value="0" >Quilômetro (km)</option>
                        <option value="1" >Metro (m)</option>
                        <option value="2" >Decímetro (cm)</option>
                        <option value="3" >Centímetro (dm)</option>
                        <option value="4" >Milímetro (mm)</option>
                        <option value="11">Micrômetro (μm)</option>
                        <option value="5" >Nanômetro (nm)</option>
                        <option value="6" >Milha (mi)</option>
                        <option value="10">Milha Náutica (nmi)</option>
                        <option value="7" >Jarda (yd)</option>
                        <option value="8" >Pé (ft)</option>
                        <option value="9" selected>Polegada (in)</option>

                    </select>
                </div>
            </div>
        </div>
    );
}

export default Comprimento;